import moment from "moment/moment";
import { css } from "./Consts";
import jwt_decode from "jwt-decode";
import { string } from "i/lib/util";
import CryptoJS from "crypto-js";

export const returnData = (data, key) => {
  let v = data?.[key];
  return v && v != "" && v != undefined && v != null ? v : "";
};

export const returnDate = (v) => {
  return v && v != "" && v != undefined && v != null && new Date(v) != "Invalid Date" ? new Date(v) : "";
};

export const returnValue = (v) => {
  return v && v != "" && v != undefined && v != null ? v : "";
};

export const returnValuewithType = (v, type) => {
  if (v && v != "" && v != undefined && v != null) {
    switch (type) {
      case "DateTime": return new Date(v) != "Invalid Date" ? moment(v).format("DD/MM/YYYY - hh:mm A") : v
      case "Date": return new Date(v) != "Invalid Date" ? moment(v).format("DD-MM-YYYY") : v
      case "Phone":
        let n = v?.replace("+91", "")?.replace(/[^0-9]/g, "")?.replace(/\s+/g, '');
        let l = n?.length;
        if (l <= 5) return n?.replace(/(\d{1})/, "+91 $1", "")
        else if (l > 5 && l <= 10) return n?.replace(/(\d{5})(\d{1})/, "+91 $1 $2", "")
        break
      // return v?.toString()?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString":
        v?.toString();
      default:
        return v;
    }
  }
  return "";
};

export const returningValue = (v, type) => {
  if (v && v != "" && v != undefined && v != null) {
    switch (type) {
      case "Date": return new Date(v) != "Invalid Date" ? new Date(v) : ""
      // case "Phone": return v?.toString()?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      case "Number": return parseInt(v);
      case "LocalTime": return new Date(v) != "Invalid Date" ? moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format("YYYY-MMM-DD H:mm:ss") : "";
      case "Decimal": return parseFloat(v).toFixed(2);
      case "DateTime": return v == "Invalid date" ? "-" : v;
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString": v?.toString();
      case "Phone": v?.toString();
      default:
        return v;
    }
  }
  return "";
};

export const checkedFn = (data, value) => {
  return data && data == value ? true : false;
};

export const returnErrorColor = (condition) => {
  let v = condition;
  return v && v != undefined && v != null && v != "" ? "border border-danger" : "";
};
export const returnErrorMessage = (condition) => {
  let v = condition;
  return v && v != undefined && v != null && v != "" ? v : "";
};

export const phoneNumberValue = (data, key) => {
  let v = data?.[key];
  return v && v != "" && v != undefined && v != null ? v?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "";
};

export const reconvertingPhonenumber = (data, array) => {
  array.map((e, i) => {
    if (data[e] && data[e] != "" && data[e].length != 0) {
      data[e] = data[e].replace(/[^0-9]/g, "");
    }
  });
};
export const reconvertingPhonenumberinArray = (dataArray, array) => {
  dataArray?.map((data, i) => {
    array?.map((e, j) => {
      if (data[e] && data[e] != "" && data[e].length != 0) {
        data[e] = data[e]?.toString()?.replace(/[^0-9]/g, "");
      }
    });
  });
};

export const getUserfromSS = (key) => {
  return key ? JSON.parse(sessionStorage.getItem("user"))?.[key] || "" : JSON.parse(sessionStorage.getItem("user"));
};

export const stringToArray = (array, data, id, label) => {
  let temp = [];
  if (array?.length > 0) {
    let tempArray = data?.split(",");
    tempArray?.map((e, i) => {
      let t = array.filter((v) => v?.[id] == parseInt(e))?.[0];
      if (t != undefined) {
        temp.push({ label: t?.[label], value: t?.[id] });
      }
    });
  }
  return temp || [];
};

export const stringToArray2 = (array, data) => {
  let temp = [];
  if (array?.length > 0) {
    let tempArray = data?.split(",");
    tempArray?.map((e, i) => {
      temp.push({ label: e, value: e });
    });
  }
  return temp || [];
};

export const returnStatusColor = (status) => {
  switch (status) {
    case "Requested":
      return css.Request;
    case "Booked":
      return css.Booked;
    case "Consulted":
      return css.Preadmission;
    case "Collected":
      return css.Collected;

    case "In Lab":
      return css.Complete;
    case "InSurgery":
      return css.Booked;
    case "Discharge":
      return css.Preadmission;
    case "Completed":
      return css.Complete;
    case "WaitList": return css.pending
    case "Cancelled": return css.red
  }
};

export const appointmentStatusList = (currentStatus) => {
  switch (currentStatus) {
    case "WaitList": return ["WaitList", "Booked"]
    case "Cancelled": return ["Cancelled"]
    case "Requested":
      return ["Requested", "Booked", "Consulted", "Completed"];
    case "Booked":
      return ["Booked", "Consulted", "Completed"];
    case "Consulted":
      return ["Consulted", "Completed", "Procedure"];
    case "Procedure":
      return ["Procedure", "InSurgery", "Discharge", "Completed"];
    case "InSurgery":
      return ["InSurgery", "Discharge", "Completed"];
    case "Discharge":
      return ["Discharge", "Completed"];
    case "Completed":
      return ["Completed"];
  }
  return [];
};

export const labAppointmentSTatusList = (currentStatus) => {
  switch (currentStatus) {
    case "Requested":
      return ["Requested", "Collected", "In Lab", "Completed"];
    case "Collected":
      return ["Collected", "In Lab", "Completed"];
    case "In Lab":
      return ["In Lab", "Completed"];
    case "Completed":
      return ["Completed"];
  }
  return [];
};

export const settingdata = (data, keys) => {
  let temp = {};
  keys.map((e, i) => {
    temp[e] = data[e] || "";
  });
  return temp;
};

export const dateinformate = (v) => {
  return v != null && v != "" && v != undefined ? moment(v).format("DD-MM-YYYY") : " NA ";
};

export const logOutFunction = () => {
  sessionStorage.clear();
  window.location.pathname = "/";
};

export const checkLoginStatus = () => {
  let authenticated = false;
  let token = sessionStorage.getItem("token");
  if (Object.keys(jwtDecodedData())?.length > 0 && token != "") {
    authenticated = (jwtDecodedData()?.id == getUserfromSS("userId")) == true ? true : false;
  }
  return authenticated;
};

export const jwtDecodedData = () => {
  if (sessionStorage.getItem("token") && sessionStorage.getItem("token") != "") {
    let res = jwt_decode(sessionStorage.getItem("token"));
    return res;
  }
  return {};
};

export const getUniqueByKey = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()] || [];
};

export const stringToInt = (data, keys) => {
  keys?.map((e, i) => {
    if (data[e] == null || data[e] == "") {
      data[e] = 0;
    } else if (typeof data[e] == string) {
      data[e] = parseInt(data[e]);
    }
  });
  return data;
};

export const sortingList = (array, key) => {
  return array.sort((a, b) => (a?.[key] > b?.[key] ? 1 : a?.[key] < b?.[key] ? -1 : 0));
};

export const encryptData = (data) => {
  console.log(data, "datatatat")
  let en = CryptoJS.AES.encrypt(data?.toString(), "AROHS")?.toString();
  // var encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  // var decoded = CryptoJS.enc.Hex.parse(encoded).toString(CryptoJS.enc.Base64);
  // var decrypted = CryptoJS.AES.decrypt(decoded, "AROHS").toString(CryptoJS.enc.Utf8);
  // let en = CryptoJS.AES.encrypt(data.toString(), 'AROHS', {
  //   mode: CryptoJS.mode.ECB,
  //   padding: CryptoJS.pad.NoPadding
  // }).toString();
  let encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  return encoded;
};

export const decryptData = (encoded) => {
  let temp = {};
  Object.entries(encoded)?.map(([key, value], i) => {
    if (i > 0) {
      let decoded1 = CryptoJS.enc.Hex.parse(value).toString(CryptoJS.enc.Base64);
      let decrypted1 = CryptoJS.AES.decrypt(decoded1, "AROHS")?.toString(CryptoJS.enc.Utf8);
      temp[key] = decrypted1;
    }
  });
  return temp;
};

export const removeDuplicates = (list, key) => {
  let result = [];
  list.filter((ele) => {
    if (result.filter((element) => element?.[key] == ele?.[key])?.length == 0) {
      result.push(ele);
    }
  });
  return result;
};

export const mobileDetect = () => {
  var agent = window.navigator.userAgent;
  var deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName("body")[0].clientWidth;

  // Chrome
  // var IsChromeApp = window.chrome && chrome.app && chrome.app.runtime;

  // iPhone
  var IsIPhone = agent.match(/iPhone/i) != null;

  // iPad up to IOS12
  var IsIPad = agent.match(/iPad/i) != null || (agent.match(/iPhone/i) != null && deviceWidth > 750); // iPadPro when run with no launch screen can have error in userAgent reporting as an iPhone rather than an iPad. iPadPro width portrait 768, iPhone6 plus 414x736 but would probably always report 414 on app startup

  if (IsIPad) IsIPhone = false;

  // iPad from IOS13
  var macApp = agent.match(/Macintosh/i) != null;
  if (macApp) {
    // need to distinguish between Macbook and iPad
    var canvas = document.createElement("canvas");
    if (canvas != null) {
      var context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      if (context) {
        var info = context.getExtension("WEBGL_debug_renderer_info");
        if (info) {
          var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
          if (renderer.indexOf("Apple") != -1) IsIPad = true;
        }
      }
    }
  }

  // IOS
  var IsIOSApp = IsIPad || IsIPhone;
  // Android
  var IsAndroid = agent.match(/Android/i) != null;
  var IsAndroidPhone = IsAndroid && deviceWidth <= 960;
  var IsAndroidTablet = IsAndroid && !IsAndroidPhone;
  console.log(IsIOSApp, IsAndroid, IsAndroidPhone, IsAndroidTablet, "here");
  var message = "";
  if (IsIPhone) {
    message = "Device is IsIPhone";
  } else if (IsIPad) {
    message = "Device is ipad";
  } else if (IsAndroidTablet || IsAndroidPhone || IsAndroid) {
    message = "Device is Android";
  } else {
    message = "Device is Mac ||  Windows Desktop";
  }
  return {
    message: message,
    isMobile: IsIOSApp || IsAndroid || IsAndroidTablet || IsAndroidPhone,
  };
};

export const filterFunction = (fil, array, filyterKeys) => {
  let f = fil?.toLowerCase()?.split(" ")?.join("");
  let res = array.filter((e) => {
    return filyterKeys.some((a) => {
      return e[a]?.toLowerCase()?.split(" ")?.join("")?.includes(f);
    });
  });
  return res;
};

export const navigationFn = (user) => {
  let roleId = user?.roleId;
  let route = "";
  if (roleId == 2) {
    route = "/v1/management/hospital/all-hospital";
  } else if (roleId == 3) {
    route = "/v1/practice/appointment/dashboard";
  } else if (roleId == 5) {
    route = "/v1/practice/appointment/patientdashboard";
  } else if (roleId == 4) {
    let credStatus = user?.credStatus;
    if (credStatus == 3 || credStatus == 4 || credStatus == 6) {
      route = "/v1/practice/appointment/physiciandashboard";
    } else {
      if (user?.termsCondStatus == "Accepted") {
        route = `/cred/welcomecred/${encryptData(user?.userId)}`;
      } else {
        route = "/cred/termsandconditons";
      }
    }
  } else if (roleId == 10) {
    route = "/v1/payroll/employee/all-employees";
  }
  return route
};
